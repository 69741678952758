.contact-section {
    background-color: white;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

}
.spacing{
    color: red;
    height: 40px;

}
.mapi{
    width: 100%;
}
hr {
    border-top: solid red;
}
@media screen and (min-width: 799px) {
    .contact-section {
        /*--offset-height: -100px;*/

        display: grid;
        grid-template-columns: 3fr 1.5fr;
        width: 80vw;
        height: 475px;
        margin: 0 auto;
        /*top: var(--offset-height);*/
        left: 50%;
        margin-left: -40vw;
        /*margin-bottom: var(--offset-height);*/
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
    }
}
