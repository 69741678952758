.navbar {
    background: red;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}
.logo-img {
    /*position: absolute;*/
    padding-top: 0px;
    margin-top: 20px;
    border-radius: 70%;
}
.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}
:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    /*color: #242424;*/
    transition: 250ms;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: red;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }
    .logo-img {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(15%, 25%);
        margin-left: 1px;
    }
    .navbar-logo {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}

