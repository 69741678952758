.footer {
    display: flex;
    justify-content: center;
    background-color: black;
    color: var(--white);
    padding-bottom: 0px;
    height: 30px;
    width: 100%;
}

.footer-copyright {
    font-size: 0.8rem;
    opacity: 0.7;
}
.phone{
    color: green;
}
.chatLink {
    color: white;
    background-color: cornflowerblue;
    border-radius: 5px;
}
@media (max-width: 1000px) {
.footer{
    padding: 70px 30px;

}
}
