.clients{
    display: flex;
    flex: 1;
    /*margin: 0 1rem;*/
    /*margin-right: 10%;*/
    margin-left: 25%;
    border-radius: 10px;
    margin-bottom: 4px;

}
.img-client{
    border-radius: 50%;
    height: 10%;
    width: 25%;
    border-spacing:190px ;
    margin-right: 5%;

}
.text-client {

}
.bottom-client{
    margin-left: 20%;
}
.sodera-client{
    border-radius: 50%;
    height: 50%;
    width: 45%;

}
